$primary: #7CB932;

$text-white: #ffffff;
$text-grey: #434242;
$text-error: #ff0000;
$text-grey: #434242;

$border-grey: #434242;
$border-error: #ff0000;

$content-white: #ffffff;
$content-grey: #5b5b5b;
$content-grey-light: #EBECEC;
$content-grey-dark: #434242;
$content-black: #000000;
