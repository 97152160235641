@import "../../assets/scss/colors";
@import "../../assets/scss/utils";

.dossier--form {
    background-color: $content-white;
    border-radius: rem(4);
    margin-bottom: rem(32);
    padding: rem(32);
    @include font-size(25);

    &--element {
        margin-bottom: rem(10);

        &--company {
            width: 100%;
        }
    }

    &--person {
        margin-left: rem(-32);

        & > div {
            float: left;
            padding-left: rem(32);
            width: 40%;
        }

        & > div:first-of-type {
            width: 20%;
        }
    }

    &--person:after {
        content: "";
        display: block;
        clear: both;
    }

    &--address {
        margin-left: rem(-32);

        & > div {
            float: left;
            padding-left: rem(32);
            width: 25%;
        }

        & > div:nth-child(1) {
            width: 35%;
        }

        & > div:nth-child(2) {
            width: 15%;
        }
    }

    &--address:after {
        content: "";
        display: block;
        clear: both;
    }

    &--communication {
        margin-left: rem(-32);

        & > div {
            float: left;
            padding-left: rem(32);
            width: 50%;
        }
    }

    &--communication:after {
        content: "";
        display: block;
        clear: both;
    }

    &--car {
        margin-left: rem(-32);

        & > div {
            float: left;
            padding-left: rem(32);
            width: 50%;
        }
    }

    &--car:after {
        content: "";
        display: block;
        clear: both;
    }
}
