@import "../../../assets/scss/colors";
@import "../../../assets/scss/utils";

.page-login {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &--content {
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #page_content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--reset-hint {
        background-color: $content-white;
        width: rem(456);
        height: rem(277);
        @include font-size(24);
        padding: rem(24);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: rem(10);
        text-align: center;
    }
}
