@import "../../../assets/scss/colors";
@import "../../../assets/scss/utils";

.page-kunden-reaktion {
	background-color: $content-grey-light;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;

	.bui-card {
		background-color: $content-white;
		border-radius: rem(10);
		max-width: rem(400);
		padding: rem(16) rem(32) rem(32);
		display: flex;
		flex-direction: column;
		text-align: center;
		@include font-size(18);

		& > img {
			padding: rem(16) rem(16) rem(32);
			display: block;
            width: rem(200);
            margin: 0 auto;
		}

		& > span {
			padding: 0 rem(16);
			margin-bottom: rem(30);
		}
	}
}
