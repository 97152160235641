@import "./colors";
@import "./fonts";
@import "./utils";

*,
*::before,
*::after {
    position: relative;
    box-sizing: border-box;
    appearance: none !important;
    -webkit-appearance: none !important;
}

h1 {
    text-align: center;
    font-weight: bold;
    @include font-size(35);
    margin: rem(50) 0;
}

h2 {
    text-align: center;
    @include font-size(25);
    font-weight: bold;
}
