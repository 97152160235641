@import "../../../assets/scss/colors";
@import "../../../assets/scss/utils";

.page-passwort-reset {
	min-height: 100%;
	display: flex;
	flex-direction: column;

	& header,
	& footer {
		flex: 0 0 auto;
	}

	&--content {
        flex: 1 0 auto;
        
        &--card {
            width: 100%;
            max-width: rem(769);
            background-color: $content-white;
            border-radius: rem(10);
            margin: 0 auto;
            padding: rem(30) rem(60);

            & .bui-input{
                margin-bottom: rem(8);
            }

            & .bui-button {
                width: 100%;
                max-width: rem(400);
                height: rem(56);
                margin: 0 auto;
                display: flex !important;
            }
        }
	}
}
