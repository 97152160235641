@import "../../../assets/scss/colors";
@import "../../../assets/scss/utils";

.page-neuer-vorgang {
	min-height: 100%;
	display: flex;
	overflow: auto;
	flex-direction: column;

	&--content {
		width: 100%;
		max-width: rem(1280);
		margin: 0 auto;
		padding: 0 rem(16) rem(96);

		&--questions {
			& > div {
				background-color: $content-white;
				border-radius: rem(4);
				margin-bottom: rem(32);
				text-align: center;
				padding: rem(32);
				@include font-size(25);
				opacity: 1;
				transition: opacity 500ms ease-in;
				height: auto;
				overflow: initial;

				& > b {
					margin-bottom: rem(24);
					display: block;
				}
			}

			&--place-of-sale .bui-select {
				width: 100%;
				max-width: rem(490);
				margin: 0 auto;
				text-align: left;
			}
		}

		.dossier--form-upload {
			opacity: 1;
			transition: opacity 500ms ease-in;
			height: auto;
			overflow: initial;
		}

		.dossier--form-upload.hidden {
			opacity: 0;
			height: 0;
			padding: 0;
			margin: 0;
			overflow: hidden;
		}

		&--submit {
			display: flex !important;
			height: rem(64);
			max-width: rem(400);
			margin: rem(32) auto 0;
		}
	}

	&--overlay--upload {
		@include font-size(16);
		top: 0 !important;
		height: 100%;
		padding: rem(256) rem(16);
		overflow: auto;

		&--headline {
			@include font-size(32);
			display: block;
			text-align: center;
			padding-bottom: rem(32);
		}

		&--row {
			display: flex;
			justify-content: space-between;
			padding: rem(8) 0;

			&:first-of-type {
				flex: 1 1 auto;
			}
		}

		& .bc-overlay--container {
			max-width: rem(640);
		}

		& .bui-button {
			display: block !important;
			max-width: rem(154);
			margin: rem(32) auto 0;
		}

		&--error {
			display: block;
			padding: rem(15) 0;
			color: $text-error;
		}

		.error {
			color: $text-error;
		}
	}
}
