@import "../assets/scss/commons";

html,
body,
#app_root {
	height: 100%;
	padding: 0;
	margin: 0;
	-webkit-text-size-adjust: none;
	font-family: "Roboto Condensed", sans-serif;
	font-weight: 400;
	color: $text-grey;
	display: flex;
	flex-direction: column;
}

body {
	background: #ebecec;
}

.bc-header--content--title--headline > img {
	height: rem(27);
}

.bc-header--content--title--headline--subline {
	@include font-size(20);
	font-weight: normal;
	font-style: italic;
}

.primary {
	background-color: $primary !important;
	border-color: $primary !important;
	color: $text-white !important;

	&--font {
		color: $primary !important;
	}
}

#page_content {
	flex: 1 1 auto;
	width: rem(1250);
	margin: 0 auto;
}
