@import "../../../assets/scss/utils";
@import "../../../assets/scss/colors";

.page-versendete-vorgaenge {
	&--headline {
		margin: rem(88) 0 rem(64);
	}

	&--pagination--wrapper {
		width: 100%;
		text-align: center;
	}

	.bc-search-bar {
		margin-bottom: rem(64);
	}

	.bc-data-table {
		margin-bottom: rem(32);

		tr > td:nth-child(4),
		tr > td:nth-child(5) {
			text-align: right;
		}

		.action-column {
			background-color: $content-grey-dark;
			text-align: center;
			cursor: pointer;

			.bui-icon {
				width: rem(28) !important;
			}

			&.checked {
				background-color: $primary;
			}

			&.disabled {
				background-color: $content-white;
				cursor: initial;

				.bui-icon {
					opacity: 0.1;
				}
			}
		}
	}

	.bc-pagination {
		margin: 0 auto rem(64);
	}
}
