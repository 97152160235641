@import "../../../assets/scss/colors";
@import "../../../assets/scss/utils";

.page-vorgang-detail {
	min-height: 100%;
	display: flex;
	overflow: auto;
	flex-direction: column;

	&--content {
		width: 100%;
		max-width: rem(1280);
		margin: 0 auto;
		padding: 0 rem(16) rem(96);
		flex: 1 1 auto;

		&--header {
			display: flex;
			justify-content: space-between;
			margin: rem(50) 0;
			align-items: flex-start;

			&--headline {
				text-align: center;
				margin-top: rem(-5);

				h1 {
					margin: 0;
				}

				h2 {
					margin: 0;
					@include font-size(20);
					font-weight: normal;
				}
			}
		}

		&--header div:last-of-type {
			min-width: rem(166);
		}

		.btn-completed {
			min-width: rem(220);
		}

		&--info-boxes {
			display: flex;
			justify-content: space-between;
			margin-bottom: rem(30);
			margin-left: rem(-30);

			& > div {
				width: 50%;
				margin-left: rem(30);
			}

			.full {
				width: 100%;
			}

			& .list-customer td.text-highlight {
				font-weight: bold;
			}

			& .list-customer tr:first-of-type td:last-of-type {
				color: $primary;
				font-weight: bold;
				@include font-size(25);
			}

			&--right {
				display: flex;
				flex-direction: column;
			}
		}

		&--residual-value {
			background-color: $content-white;
			border-radius: rem(10) rem(10) 0 0;
			margin-bottom: rem(1);
			padding: rem(16);
			display: flex;
			justify-content: space-between;
			align-items: center;

			&--label {
				flex: 0 0 auto;
				width: rem(160);
			}

			&--price {
				flex: 1 1 auto;
				font-weight: bold;
				@include font-size(40);
				text-align: right;
			}
		}

		&--advisor {
			background-color: $content-white;
			padding: rem(16);
			flex: 1 1 auto;

			&--name,
			&--seller-nr,
			&--email,
			&--phone {
				display: flex;
				justify-content: space-between;
			}

			&--label {
				width: rem(160);
				flex: 0 0 auto;
			}

			&--value {
				flex: 1 1 auto;
				text-align: left;
			}

			&--name {
				margin: rem(16) 0;
				align-items: center;

				& > span:last-child {
					@include font-size(24);
					color: $primary;
					font-weight: bold;
				}
			}

			&--seller-nr {
				margin-bottom: rem(16);
			}
		}

		&--hint-area {
			background-color: $content-white;
			border-radius: 0 0 rem(10) rem(10);
			padding: rem(16);

			.bui-button {
				float: right;
			}

			&--saving-hint {
				@include font-size(18);
				font-weight: bold;
				display: inline-flex;
				align-items: center;
				height: rem(40);

				&.error {
					color: $text-error;
				}
			}
		}
	}

	.bui-info-box {
		border-radius: rem(10);
	}
}
